import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import EventInfo from "../components/EventInfo";
import { Helmet } from "react-helmet";
import EventImage from "../components/EventImg";

function EventPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const eventId = searchParams.get("eventId");

  const [event, setEvent] = useState({});

  useEffect(() => {
    async function fetchEvent() {
      try {
        const response = await fetch(`https://api.tickazo.com/eventos/${eventId}`);
        const data = await response.json();
        setEvent(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchEvent();
  }, [eventId]);

  if (loading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    }}>
        <CircularProgress color="primary"/>
    </div>;
  }

  return (
    <>
      <Helmet>
        <title>Tickazo | {event.nombre}</title>
        <meta name="description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar."/>
        <meta property="og:title" content={`Tickazo | ${event.nombre}`} />
        <meta property="og:url" content={`https://tickazo.com/events?eventId=${eventId}`} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar."/>
        <meta property="og:image" content={event.img_poster} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Tickazo | ${event.nombre}`} />
        <meta name="twitter:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar." />
        <meta name="twitter:image" content="https://tickazo.com/images/tickazo-logo.png" />
        <meta name="twitter:image:alt" content="Tickazo logo" />
      </Helmet>
      <div style={{ margin: '16px', display: 'flex', justifyContent: 'center' }}>
        <Grid container alignItems="center" className="event-page-container">
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <EventImage img_header={event.img_header} nombre={event.nombre} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <EventInfo event={event} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EventPage;